import { Box, Button, FormControl, MenuItem, Select, TextField, Typography, Hidden, useMediaQuery } from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import headerIcon from '../assets/images/header_icon.png'
import theme from "../utils/theme";
import MenuDrawer from "./Drawer";
import ApiService from "../api_service/api_service";

const Header = () => {
    var [isSearch, setIsSearch] = React.useState(false);
    var [name, setName] = React.useState('');
    const [ categories, setCategories ] = React.useState([]);
    const [category, setCategory] = React.useState('');

    React.useEffect(() => {
     const getCategories = async () => {
            let response = await ApiService.categories();
            setCategories(response.data);
            if(categories.length > 0){
                setCategory(categories[0]);
            }
     };
     getCategories();
    }, []);

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box sx={{
                p: isSmallScreen ? '20px' : '20px 100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <Box component={Link}  to='/' sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <img
                        src={headerIcon}
                        alt='Header Icon'
                        loading="lazy"
                        style={{ width: theme.palette.logoSize.large }}
                    />
                    <Box width='10px' />
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontSize: theme.palette.logoFontsize.large, fontWeight: 'bold', color: theme.palette.primary.main }}>SUPER MOBILE</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Hidden mdDown>
                        <Box sx={{ border: '1px solid #CCCCCC', borderRadius: '5px', display: 'flex' }}>
                            
                            <FormControl>
                            <TextField
                                variant="outlined"
                                label='Search Products ...'
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '0px', // Set border radius to 0px
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '0px !important', // Ensure the outline border radius is also 0px
                                    },
                                }}
                                onChange={(event) => {
                                    setIsSearch(false);
                                    setName(event.target.value);
                                }}
                            />
                                {/* <Select
                                    value={category}
                                    onChange={handleChange}
                                    size='small'
                                    sx={{
                                        borderRadius: '0px', // Set border radius to 0px
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0px', // Ensure the input area border radius is 0px
                                        },
                                    }}
                                >
                                    {categories.map((value) => (
                                        <MenuItem value={value._id} key={value._id}
                                        >{value.name}</MenuItem>
                                    ))}
                                </Select> */}
                            </FormControl>
                            <Button variant="contained" sx={{ borderRadius: '0px', py: '8px' }} component={Link}  to={`/all-products?name=${name}`}>
                                <SearchIcon />
                            </Button>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <MenuIcon sx={{ fontSize: '2rem' }} onClick={toggleDrawer} />
                    </Hidden>
                </Box>
            </Box>
            <MenuDrawer categories={categories} open={drawerOpen} onClose={toggleDrawer} />
        </>
    )
}

export default Header;
