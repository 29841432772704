import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../utils/theme";

const AboutUs = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            p: { xs: '20px', sm: '20px 100px', md: '100px' },
        }}>
            <Box sx={{
                width: { md: '50%' },
                pr: '50px',
                pb: { xs: '20px', sm: '20px' }
            }}>
                <Typography sx={{
                    fontSize: theme.palette.Title.fontSize,
                    fontWeight: 'bold'
                }}>About Our Company</Typography>
                <Typography sx={{
                    pt: '10px'
                }}>
                
                Super Group Co., Ltd. is founded in September 2007. The company headquartered in No.B1/2/3, 78th Street, between 27th and 28th Street, Mandalay, Myanmar. Our company’s core business is in both retail, wholesales and distributions for mobile phone and it’s accessories, spare parts, hardware, software and repair services.

Since 2004, before we started to reform Super Group Co., Ltd, we were in electronic business as “Super Electronic Shop“ in Mandalay doing both retail and whole sales. In 2012, We proudly gained successful customer relationship and decided to expend our business into Telecommunication and Mobile Phone Business Industry.

Our MISSION is to bring value for society, customers and stakeholders, through innovative, efficient and quality management for the provision of mobile phone and telecommunication equipment through the drive and development of our staff team.
                
                </Typography>
            </Box>
            <Box
                component="img"
                sx={{
                  overflow: 'hidden',
                  width: { md: '50%' },
                  height: '350px',
                  borderRadius: '10px'
                }}
                src='shop_1.jpg'
            />
        </Box>
    )
}

export default AboutUs