import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";

import headerIcon from '../../assets/images/logo2.png'

const SuperMobile = ({ screenWidth }) => {
    return (
        <Box sx={{
            width: screenWidth
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <img
                    src={headerIcon}
                    alt='Header Icon'
                    loading="lazy"
                    style={{
                        width: theme.palette.logoSize.larger
                    }}
                />
                <Box width='10px' />
                <Box sx={{
                    display: 'flex',
                }}>
                    <Typography sx={{
                        fontSize: theme.palette.logoFontsize.large,
                        fontWeight: 'bold',
                        color: theme.palette.primary.main
                    }}>SUPER MOBILE</Typography>
                </Box>
            </Box>
            <Box height='20px'/>
            <Typography>
                    Super Group Co., Ltd. is founded in September 2007. The company headquartered in No.B1/2/3, 78th Street, between 27th and 28th Street, Mandalay, Myanmar. Our company’s core business is in both retail, wholesales and distributions for mobile phone and it’s accessories, spare parts, hardware, software and repair services.

                    Since 2004, before we started to reform Super Group Co., Ltd, we were in electronic business as “Super Electronic Shop“ in Mandalay doing both retail and whole sales. In 2012, We proudly gained successful customer relationship and decided to expend our business into Telecommunication and Mobile Phone Business Industry.

                    Our MISSION is to bring value for society, customers and stakeholders, through innovative, efficient and quality management for the provision of mobile phone and telecommunication equipment through the drive and development of our staff team.

            </Typography>
        </Box>
    )
}

export default SuperMobile